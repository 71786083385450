.HostelRules {
  // display: flex;
  flex-direction: column;
  header {
    font-weight: 600;
    margin-bottom: 4px;
  }

  ul {
    height: 56px;
    overflow: hidden;
    margin-bottom: 0;
    transition: all 0.5s ease;

    &.show {
      height: auto;
    }
  }

  li {
    margin-bottom: 8px;
    font-size: 0.75rem;
  }

  button {
    // align-self: flex-end;
    padding-left: 38px;
    background: none;
    border: none;
    font-size: 14px;
    color: var(--color-primary);
    text-decoration: underline;
    cursor: pointer;
  }
}
