@import "../../styles/responsive";

.NotificationSettings {
  .up {
    &__main-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0;

      @include tablet-lg {
        flex-direction: row;
      }
    }

    &__left {
      flex: 1;
      h2 {
        color: var(--color-primary);
        font-weight: 400;
      }
    }

    &__right {
      flex: 0 0 200px;
      border-radius: 6px;
      background-color: rgba(var(--color-primary-rgb), 0.1);
    }

    &__main {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 347px);
      p {
        font-size: 32px;
        font-weight: 400;
      }
    }

    &__top-toolbar {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 0 0;
    }
  }
}
