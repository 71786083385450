@import "../../styles/responsive";

.Header {
  .header {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 20px;
      position: relative;

      img,
      a {
        height: 84px;
      }
    }

    &__user-container {
      height: 48px;
      background-color: rgba(var(--color-primary-rgb), 0.1);
      border-radius: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 0 0.75rem;
      color: var(--text-main);
      font-size: 0.875rem;
      cursor: pointer;

      >span:first-child {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        color: var(--color-primary);
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 4px 0px #00000040;
      }

      >span:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        >span:first-child {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }

  .header-button {
    background-color: #ff6600;
    font-weight: 400;
    color: #fff;
    padding: 20px;
    margin-left: 20px;
  }
}