@import "../../../styles/responsive";

.LeftSection {
  height: 25vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0 0 30px 30px;
  box-shadow: 0 2px 6px 1px rgb(0 0 0 / 20%);

  a {
    padding: 1rem 0;
  }

  img {
    max-width: 50%;
  }

  @include tablet-lg {
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 0 20px 20px 0;
    box-shadow: 4px 0 6px 1px rgb(0 0 0 / 20%);
  }
}
