.BackButton {
  background-color: rgba(255,255,255,0.72);
  border-radius: 30px;
  padding: 2px 12px;
  .bb {
    &__container {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 1rem;
        color: var(--text-main);
        cursor: pointer;

        &:hover {
            color: var(--color-primary);
        }
    }
  }
}
