@import "../../../../styles/responsive";
.FacilitiesList {
  .fc__facilities {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    color: rgba(var(--color-primary-rgb), 0.7);

    @include tablet-lg {
      
    }
  }
}
