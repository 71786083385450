.Facilities {
  header {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .fc__facilities {
    padding: 1rem 0;
    header {
      font-weight: 600;
      color: var(--text-main);
      margin-bottom: 16px;
    }

    &-main {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 0;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        width: 25%;

        span:first-child {
          color: var(--color-primary);
        }

        span:last-child {
          font-size: 0.875rem;
          color: var(--text-main);
          text-align: center;
        }
      }
    }
  }
}
