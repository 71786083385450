@import "../../styles/responsive";

.ComplaintsAndFeedback {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    margin-left: 5%;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin: auto;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .header__title {
    font-weight: 500;
    font-size: 24px;
  }
  .headerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 20px 0px 20px 0px;
    margin-left: 10%;
    transition: 0.3s ease;
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 20px 0px 20px 0px;
    margin-left: 10%;
    transition: 0.3s ease;
    cursor: pointer;
  }
  .content:hover {
    margin-left: 10.5%;
    // font-size: 15px;
    font-weight: 500;
    color: rgb(73, 91, 87);
  }
  .tableHeader{
    font-weight: 500;
    font-size: 18px;
  }
  .paginationGird {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 20px 0px 0px 0px;
    margin-left: 10%;
    transition: 0.3s ease;
  }
  .paginationContent{
    display: flex;
    margin-left: auto;
  }
  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
