@import '../../styles/responsive';

.component__footer {
  background-color: rgba(var(--color-secondary-rgb), 0.9);
  font-size: 0.75rem;
  margin-top: 10px;

  &-main {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0;

    @include tablet-lg {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  p {
    color: var(--text-white);

    @include tablet-lg {
      margin: 0;
    }
  }

  ul {
    margin: 0;
    padding: 6px 0;

    li {
      list-style: none;

      a {
        color: var(--color-primary);
        text-transform: uppercase;
        padding: 0 0.2rem;
        text-decoration: none;
      }
    }
  }
}

.page-link {
  width: 100%;
  color: #fff;
  padding-bottom: 5px;
  font-weight: 500;
}