@import "../../styles/responsive";

.BookingDetail {
  .page-title {
    padding: 0 16px;
  }
  .bd {
    &__main-container {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      padding: 0 0 1rem 0;

      @include tablet-lg {
        flex-direction: row;
        gap: 1rem;
      }
    }

    &__left {
      position: relative;
      flex: 0 0 780px;
    }

    &__right {
      padding: 1rem 1rem 0;
      position: relative;
      top: -2rem;
      background-color: #fff;

      @include tablet-lg {
        top: 0;
        flex: 0 1 480px;
        overflow: hidden;
        padding-top: 0;
      }
      button {
        width: 100%;
        height: 48px;
      }
    }

    &__top-toolbar {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: -68px;
      z-index: 2;
      padding: 12px;

      @include tablet-lg {
        margin-bottom: 0;
        padding: 0.75rem 0 0;
      }
    }

    &__accordion-container {
      color: var(--color-secondary);
    }

    &__accordion-student {
      a {
        color: var(--color-primary);
      }

      &-main {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        @include tablet-lg {
          gap: 40px;
        }

        label {
          font-size: 14px;
        }

        p {
          margin-top: 0;
        }
      }
    }

    &__payment-summary {
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      margin-bottom: 1rem;
      padding: 0 1rem 1rem;
      color: var(--color-secondary);

      h3 {
        color: var(--text-black-lighter);
      }

      &-calc {
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }
      }

      &-st {
        border-top: 1px dashed #bdbdbd;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #bdbdbd;
        margin-bottom: 30px !important;
      }

      &-gt {
        border-top: 1px dashed #bdbdbd;
        padding-top: 1rem;
        > div:last-child {
          font-weight: bold;
        }
      }

      &-ps {
        font-size: 14px;
        color: var(--color-primary);
        cursor: pointer;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 0;
      }

      &-pst {
        th {
          color: var(--color-secondary);
        }
      }
    }

    &__booking-summary {
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      margin-bottom: 1rem;
      padding: 0 1rem 1rem;
      color: var(--color-secondary);

      h3 {
        color: var(--text-black-lighter);
      }

      &-list {
        > div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;

          div:first-child {
            width: 120px;
          }

          div:last-child {
            text-align: right;
          }
        }
      }

      &-gt {
        border-top: 1px dashed #bdbdbd;
        padding-top: 1rem;
        > div:last-child {
          font-weight: bold;
        }
      }
    }

    &__rd {
      display: flex;
      gap: 1rem;
      color: var(--text-main);
      margin-bottom: 30px;

      h4 {
        margin-top: 0;
        color: rgba(var(--color-secondary-rgb), 0.8);
      }
    }

    &__rd-0 {
      &-img {
        flex-shrink: 0;
        flex-grow: 0;
        img {
          width: 100px;
          height: auto;
          border-radius: 6px;
        }
      }

      h3 {
        color: var(--color-primary);
        margin: 0;
      }

      p {
        font-size: 14px;
        margin: 0;
        word-break: break-word;
      }
    }

    &__rd-1 {
      .BookingDetailRoomOptions .ro__container {
        max-width: calc(100vw - 90px);
        padding-bottom: 8px;

        @include tablet-lg {
          max-width: 670px;
        }
      }
    }

    &__rd-2 {
      input[type="date"] {
        padding: 8px 12px;
        font-family: Roboto;
        border-radius: 4px;
        border: 1px solid #bdbdbd;
      }
    }

    &__rd-4 {
      span {
        text-transform: capitalize;
      }
    }

    &__tnc {
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      label {
        font-size: 13px;
        margin-right: 0;
      }
      span {
        color: var(--color-primary);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .booking-step {
    width: 36px;
    height: 36px;
    background: rgba(var(--color-primary-rgb), 0.1);
    border-radius: 4px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: var(--color-primary);
  }
}

.bip {
  &__main {
    height: 300px;
    max-height: 98vh;
    overflow: auto;
    width: 420px;
    max-width: 98vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
}

.bd__tnc-list {
  color: var(--text-main);
}
