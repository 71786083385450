@import "../../styles/responsive";

.HostelListPage {
  .hl {
    &__main-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0;

      @include tablet-lg {
        flex-direction: row;
      }
    }

    &__left {
      display: none;
      flex: 0 0 220px;
      @include tablet-lg {
        display: block;
      }
    }

    &__center {
      flex: 1;
    }

    &__right {
      flex: 0 0 200px;
      border-radius: 6px;
      background-color: rgba(var(--color-primary-rgb), 0.1);
      display: none;
    }

    &__tile {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      padding: 0.75rem;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;

        @include tablet-lg {
          justify-content: flex-start;
        }

        h3 {
          margin: 0;
          cursor: pointer;
          color: var(--color-primary);

          @include tablet-lg {
            max-width: 270px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        > span {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          gap: 2px;
          border-left: 1px solid rgba(0, 0, 0, 0.2);
          margin-left: 12px;
          padding-left: 8px;
          font-size: 0.875rem;
          color: var(--text-secondary);

          span {
            height: 20px;
          }

          .mat-icon {
            font-size: 1.25rem;
            color: #f6de00;
          }
        }
      }

      p {
        margin: 0;
        color: var(--text-main);
        font-size: 0.875rem;
        display: flex;
        gap: 4px;

        .mat-icon {
          font-size: 1.125rem;
        }

        span:last-child {
          @include tablet-lg {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 370px;
          }
        }
      }

      @include tablet-lg {
        flex-direction: row;
        gap: 1rem;
        height: 164px;
      }

      &-img-container {
        @include tablet-lg {
          width: 210px;
        }
        img {
          border-radius: 6px 6px 0 0;

          @include tablet-lg {
            border-radius: 6px;
            width: 210px;
          }
        }
      }

      &-main {
        flex: 1;
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 1.25rem;

        .price {
          color: var(--text-main);
          display: flex;
          align-items: flex-end;

          .mat-icon {
            font-size: 1.25rem;
          }

          span:nth-child(2) {
            line-height: 1;
            font-size: 1.25rem;
            font-weight: 500;
          }

          span:last-of-type {
            font-size: 0.75rem;
            line-height: 1;
          }
        }
      }
    }

    &__room-options {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 0.75rem;
      overflow-x: auto;
      padding-bottom: 4px;
      > span {
        background-color: rgba(var(--color-primary-rgb), 0.1);
        padding: 4px 12px;
        border-radius: 30px;
        font-size: 0.875rem;
        color: var(--text-main);
        display: flex;
        align-items: center;
        height: 32px;
        white-space: nowrap;
      }

      .gender-type {
        background-color: #fff;
        border: 1px solid;
        box-sizing: border-box;

        &.male {
          color: var(--color-male);
        }

        &.female {
          color: var(--color-female);
        }

        &.both {
          color: var(--color-neutral);
        }
      }
    }

    &__fav {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;

      @include tablet-lg {
        right: 0.75rem;
        top: 0.75rem;
      }
    }

    &__view-on-map {
      display: flex;
      width: 220px;
      height: 128px;
      background: url("../../../public/images/view-on-map.png") 0 0 no-repeat;
      background-size: contain;
      align-items: center;
      justify-content: center;
      margin: 0 0 1rem;
    }

    &__search-location {
      color: var(--text-main);
      margin-bottom: 1rem;

      > div:last-child {
        font-size: 1.25rem;
      }
    }

    &__top-toolbar {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 0 0;
    }

    &__our-recom {
      &-container {
      }

      &-title {
        font-weight: normal;
        font-size: 1.25rem;
        color: var(--text-secondary);
      }
    }

    &__chip-container {
      display: flex;
      gap: 12px;

      @include tablet-lg {
        display: none;
      }
    }
  }
}

.hl__filter {
  &-container {
    padding: 8px;
  }
  &-gender {
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    padding: 12px;

    &-header {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 13px;
        cursor: pointer;
        color: var(--color-primary);
        text-decoration: underline;
      }
    }

    h4 {
      margin-top: 0;
      margin-bottom: 12px;
      font-weight: 500;
      color: var(--text-secondary);
    }

    &-radio {
      color: var(--text-secondary);
    }
  }

  &-footer {
    display: flex;
    padding: 12px 0;
    justify-content: flex-end;
    gap: 12px;
  }
}
