@import "../../styles/responsive";

.Homepage {
  .section__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 132px);
  }

  .section__home {
    &-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem 0;

      &-user {
        background: rgba(var(--color-primary-rgb), 0.2);
        padding: 8px 16px;
        border-radius: 30px;
        color: var(--text-main);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 8rem;

      &-user {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }

    &-logo {
      margin-bottom: 1rem;

      img {
        width: 140px;
      }
    }

    &-hero-text {
      padding-top: 2rem;
      width: 480px;
      max-width: 100%;
    }

    &-ls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      font-size: 12px;
      margin-top: 4px;
      gap: 4px;

      .mat-icon {
        font-size: 16px;
      }

      span {
        cursor: pointer;
      }
    }
  }

  .component {
    &__textbox-big {
      width: 100%;

      input {
        color: var(--text-main);
      }
    }

    &__search-form {
      width: 480px;
      max-width: 100%;
    }
  }
}

.section__home-ls-main {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;

  img {
    width: 100px;
  }
}

.banner-centered-text {
  position: absolute;
  top: 20%;
  left: 20%;
  transform: translate(-10%, -10%);
  font-size: 62px;
}

.banner-left-text {
  position: absolute;
  top: 5%;
  left: 12%;
  transform: translate(-10%, -10%);
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.limitless-text-block {
  position: absolute;
  bottom: 5px;
  background-color: whitesmoke;
  color: black;
  width: inherit;
  opacity: 0.8;
  border-radius: 11px;
  width: 100%;
}

.pgfood-image-center {
  transform: translate(-15%, -25%);
}

.tribe-student-accomodation-text-block {
  position: absolute;
  height: 409px;
  background-color: black;
  width: 100%;
  opacity: 0.8;
  top: 0;
}

.ant-carousel .slick-slide img {
  display: inline;
}

.ant-carousel .slick-next,
.ant-carousel .slick-prev {
  color: #ff6600 !important;
}

@media screen and (min-width:900px) {
  .banner-centered-text-fontsize {
    font-size: 52px;
  }

  .banner-card-text-fontsize {
    font-size: 28px;
  }
}

@media screen and (min-width:820px) {
  .banner-centered-text-fontsize {
    font-size: 42px;
  }

  .banner-card-text-fontsize {
    font-size: 22px;
  }
}

@media screen and (max-width: 768px) {
  .banner-centered-text-fontsize {
    font-size: 32px;
  }
}