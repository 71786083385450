@import "../../styles/responsive";

.UserNavigation {
  .un {
    &__container {
      width: 320px;

      @include tablet-lg {
        width: 320px;
      }
    }

    &__logo {
      text-align: center;
      padding: 1rem 0;

      height: 150px;
    }

    &__user {
      &-container {
        display: flex;
        gap: 1rem;
        justify-content: center;
        border-bottom: 1px solid #bdbdbd;
        padding-bottom: 1rem;
        margin: 0 2rem;
      }

      &-pic {
        width: 54px;
        height: 54px;
        flex-shrink: 0;
        background-color: #f1f1f1;
        border-radius: 50%;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        border: 2px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 2rem;
        }
      }

      &-detail {
        color: var(--text-main);

        h3 {
          margin: 0;
        }

        p {
          margin: 0;
          width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &__nav {
      ul {
        padding: 0;
        list-style: none;
      }

      li {
        a {
          display: flex;
          align-items: center;
          height: 48px;
          gap: 12px;
          color: var(--text-main);
          padding: 0 1rem;
          position: relative;

          &.active {
            background: rgba(var(--color-primary-rgb), 0.1);

            .mat-icon {
              color: rgba(var(--color-primary-rgb), 1);
            }

            > span:last-of-type {
              font-weight: bold;
            }

            &:after {
              content: "";
              position: absolute;
              background-color: var(--color-primary);
              height: 36px;
              width: 6px;
              right: 0;
              top: 6px;
              border-radius: 30px 0 0 30px;
            }
          }
        }
      }
    }
  }
}
