@import "../../styles/responsive";

.HostelDetailPage {
  .hd {
    &__main-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0;

      @include tablet-lg {
        flex-direction: row;
      }

      > aside {
        display: none;
        flex: 0 0 200px;
        border-radius: 6px;
        background-color: rgba(var(--color-primary-rgb), 0.1);

        @include tablet-lg {
          display: block;
        }
      }
    }

    &__main {
      position: relative;
      @include tablet-lg {
        display: flex;
      }
    }

    &__left {
      position: relative;

      @include tablet-lg {
        flex: 0 0 470px;
      }
    }

    &__img-container {
      display: flex;
      overflow: auto;
      cursor: pointer;

      @include tablet-lg {
        flex-wrap: wrap;
        gap: 8px;
      }

      &-left {
        display: none;

        @include tablet-lg {
          display: flex;
          flex: 1 0 100%;
          gap: 8px;
          flex-wrap: wrap;
          flex: 0 0 308px;
        }

        img:first-child {
          @include tablet-lg {
            width: 308px;
            height: 208px;
            border-top-left-radius: 10px;
          }
        }

        img:nth-child(2) {
          @include tablet-lg {
            border-bottom-left-radius: 10px;
          }
        }
      }

      &-right {
        display: none;

        @include tablet-lg {
          display: flex;
          flex: 1 0 100%;
          flex: 0 0 150px;
          gap: 8px;
          flex-wrap: wrap;
        }
        img:first-child {
          @include tablet-lg {
            border-top-right-radius: 10px;
          }
        }
        img:last-child {
          @include tablet-lg {
            border-bottom-right-radius: 10px;
          }
        }
      }

      &-mobile {
        overflow: auto;
        display: flex;
        gap: 4px;

        @include tablet-lg {
          display: none;
        }
      }
      img {
        @include tablet-lg {
          width: 150px;
          height: 100px;
        }
      }
    }

    &__right {
      padding: 1rem 1rem 0;
      border-radius: 30px 30px 0 0;
      position: relative;
      top: -2rem;
      background-color: #fff;
      box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.25);

      @include tablet-lg {
        border-radius: 0;
        box-shadow: none;
        top: 0;
        flex: 0 1 480px;
        overflow: hidden;
        padding-top: 0;

        .HostelName {
          margin-right: 40px;
        }
      }
    }

    &__room-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      margin-top: 0.75rem;
      margin-bottom: 1rem;

      > div {
        flex: 1 1;
        background-color: rgba(var(--color-primary-rgb), 0.1);
        padding: 12px;
        border-radius: 10px;
        color: var(--text-main);
        height: 150px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .mat-icon {
          font-size: 2.5rem;
          color: #6227c1;
        }

        h3 {
          font-weight: 400;
          margin: 0;
        }

        .price {
          color: var(--text-main);
          display: flex;
          align-items: flex-end;
          align-self: flex-end;
          justify-content: flex-end;
          flex-wrap: wrap;

          .mat-icon {
            font-size: 1.25rem;
          }

          span:nth-child(2) {
            line-height: 1;
            font-size: 1.25rem;
            font-weight: 500;
          }

          span:last-of-type {
            font-size: 0.75rem;
            line-height: 1;
          }
        }
      }

      @include tablet-lg {
        display: flex;
      }
    }

    &__description {
      color: var(--text-main);
      padding: 1rem 0;

      header {
        font-weight: 600;
        margin-bottom: 4px;
      }

      p {
        margin: 0;
        font-size: 0.875rem;
      }
    }

    &__fav {
      position: absolute;
      right: 1.5rem;
      top: 1rem;

      @include tablet-lg {
        display: none;
      }
    }

    &__fav-right {
      display: none;
      position: absolute;
      right: 1rem;
      top: 0;

      @include tablet-lg {
        display: block;
      }
    }

    &__location-map {
      padding: 2rem 0 1rem;

      @include tablet-lg {
        display: none;
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 16px;

        span {
          font-size: 0.875rem;
          text-decoration: underline;
        }
      }
      header {
        font-weight: 600;
        color: var(--text-main);
      }

      &-container {
        border: 1px solid;
        border-radius: 10px;
        padding: 8px;
        height: 240px;
      }
    }

    &__nearby {
      padding: 1rem 0;
      header {
        font-weight: 600;
        color: var(--text-main);
        margin-bottom: 12px;
      }

      &-main {
        display: flex;
        gap: 4px;
      }

      &-left {
        width: 130px;

        > div {
          background: rgb(241, 241, 241);
          height: 40px;
          padding: 8px 12px;
          margin-bottom: 4px;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          color: var(--text-main);
          cursor: pointer;

          &.active {
            background-color: rgba(var(--color-primary-rgb), 0.9);
            color: var(--text-white);
          }

          &:first-child {
            border-top-left-radius: 10px;
          }

          &:last-child {
            border-bottom-left-radius: 10px;
            margin-bottom: 0;
          }
        }
      }

      &-right {
        background-color: rgb(241, 241, 241);
        flex: 1;
        border-radius: 0 10px 10px 0;
        padding: 12px;
        height: 216px;
        overflow: auto;

        ul {
          margin: 0;
          padding-left: 20px;

          li {
            font-size: 13px;
            > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 6px 0;
            }
            span {
              color: var(--text-main);
            }

            span:last-of-type {
              white-space: nowrap;
            }
          }
        }
      }
    }

    &__btn-container {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      @include tablet-lg {
        flex-direction: row;
      }

      button {
        width: 240px;

        @include tablet-lg {
          width: 200px;
        }
      }
    }

    &__view-on-map {
      display: none;
      width: 240px;
      height: 128px;
      background: url("../../../public/images/view-on-map.png") 0 0 no-repeat;
      background-size: cover;
      align-items: center;
      justify-content: center;
      margin: 2rem 0 1rem;

      @include tablet-lg {
        display: flex;
      }
    }

    &__rules {
      display: none;
      color: var(--text-main);
      padding: 1rem 0;

      @include tablet-lg {
        display: block;
      }

      &-right {
        color: var(--text-main);
        padding: 1rem 0;
        @include tablet-lg {
          display: none;
        }
      }
    }

    &__top-toolbar {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: -68px;
      z-index: 2;
      padding: 12px;

      @include tablet-lg {
        margin-bottom: 0;
        padding: 0.75rem 0 0;
      }
    }
  }
}
