.ForgotPassword {
  .auth {
    &__left-section {
      background-image: url("../../../public/images/room2.jpg");
    }

    &__form {
      p {
        color: var(--text-secondary);
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}
