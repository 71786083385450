@import "../../styles/responsive";

.UpdateProfile {
  .up {
    &__main-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      padding: 1rem 0;

      @include tablet-lg {
        flex-direction: row;
        min-height: calc(100vh - 199px);
      }
    }

    &__left {
      flex: 1;

      h2 {
        color: var(--color-primary);
        font-weight: 400;
      }
    }

    &-otp {
      display: flex;
      gap: 8px;
    }

    &__right {
      flex: 0 0 50px;
      border-radius: 6px;
      background-color: rgba(var(--color-primary-rgb), 0.1);
    }

    &__main {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 347px);

      p {
        font-size: 32px;
        font-weight: 400;
      }
    }

    &__tile {
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      margin-bottom: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      padding-right: 30px;

      h3 {
        color: var(--text-secondary);
      }

      &-profile {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;

        @include tablet-lg {
          flex-direction: row;
          align-items: center;
        }

        &-main {
          display: flex;
          flex-direction: column;
          gap: 20px;

          @include tablet-lg {
            flex-direction: row;
          }
        }

        &-pic {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          background-color: #f5f5f5;
          display: flex;
          justify-content: center;
          align-items: center;

          .mat-icon {
            font-size: 40px;
            display: block;
            color: rgba(var(--color-primary-rgb), 0.6);
          }
        }

        &-name {
          >div:first-child {
            color: var(--color-primary);
            font-size: 28px;
          }

          >div:last-child {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            gap: 20px;

            @include tablet-lg {
              flex-direction: row;
              align-items: center;
              gap: 40px;
            }

            >div {
              display: flex;
              align-items: center;
              gap: 8px;

              &:first-child {
                @include tablet-lg {
                  padding-right: 40px;
                  border-right: 1px solid #e5e5e5;
                }
              }
            }

            label {
              font-size: 14px;
              margin: 0;
            }

            p {
              margin: 0;
              font-size: 16px;
            }

            .mat-icon {
              color: #bdbdbd;
            }
          }
        }

        &-btn {
          align-self: flex-end;
          justify-content: flex-end;
        }
      }

      &-security {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.mainContainer {
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;


  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .topRow_title {
      font-style: Roboto;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.64);
      // color: var(--color-primary);
      margin-top: 0px;
    }

    .subText {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.64);
    }

    .topRow_profilePic {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      .mat-icon {
        font-size: 50px;
        display: block;
        color: rgba(rgba(112, 109, 109, 0.735), 0.6);
      }
    }

    .topRow_btn {
      font-size: 16px;
      font-weight: 400;
      color: var(--color-primary);
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      margin-top: 0px;
    }
  }

  .infoRow {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    padding-left: 10px;
    // justify-content: space-between;

    @include tablet-lg {
      flex-direction: row;
      align-items: center;
    }

    .mainSpan {
      font-size: 15px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.64);
    }

    .verifiedSpan {
      font-size: 20px;
      font-weight: 500;
      color: rgba(76, 175, 80, 1);
    }

    .nonVerifiedSpan {
      font-size: 20px;
      font-weight: 500;
      color: rgba(193, 39, 45, 1);
    }

    .infoRow_name {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7);
      margin: 0;
      width: 30%;
    }

    .infoRow_value {
      font-size: 15px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.64);
      width: 50%;
      justify-content: center;
    }

    .infoRow_btn {
      font-size: 16px;
      font-weight: 400;
      color: var(--color-primary);
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 30%;
    }

  }
}


.line {
  // width: 90%;
  height: 1px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-top: 5px;
  margin-bottom: 5px;
}

.verticalLine {
  width: 1px;
  border-left: 1px solid rgba(217, 217, 217, 1);
  margin-top: 5px;
  margin-bottom: 5px;
}

.up__change-popup {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding-top: 20px;
}

.component__textbox-otp {
  width: 52px;
  height: 52px;

  input {
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }
}

.component__textbox-bigX {
  width: 474px;
  // height: 200px;

  TextField {
    font-size: 0.9rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

}

.pText__danger {
  padding-left: 2rem;
  padding-bottom: 1rem;
  color: rgb(209, 92, 96);
  font-size: 12px;
  font-weight: 400;
}