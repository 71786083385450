.Breadcrumb {
  .breadcrumb {
    &__container {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 1rem;
        color: var(--text-main);

        a:hover {
            color: var(--color-primary);
        }
    }
  }
}
