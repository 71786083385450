@import "../../styles/responsive";

.Logout {
  .logout {
    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      min-height: calc(100vh - 186px);
      color: var(--text-main);
    }
  }
}
