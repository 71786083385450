@import "../../styles/responsive";

.Share {
    .share__container {
        background-color: rgba(var(--color-primary-rgb), 0.1);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px 0;

        &.white {
            background-color: rgba(255,255,255, 0.72);
        }

        span {
            font-size: 1.125rem;
        }
    }
}