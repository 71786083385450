@import "../../../styles/responsive";

.HostelName {
  .hn {
    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      h3 {
        margin: 0;
        font-size: 20px;
        color: var(--color-primary);
      }

      > span {
        display: flex;
        align-items: center;
        gap: 2px;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        margin-left: 12px;
        padding-left: 8px;
        font-size: 0.875rem;
        color: var(--text-secondary);

        span {
          height: 20px;
        }

        .mat-icon {
          font-size: 1.25rem;
          color: #f6de00;
        }
      }
    }

    &__location {
      margin-bottom: 20px;
      p {
        margin: 0;
        color: var(--text-main);
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        gap: 4px;

        > span:last-child {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          
          @include tablet-lg {
            white-space: normal;
          }
        }

        .mat-icon {
          font-size: 1.125rem;
        }
      }
    }
  }
}
