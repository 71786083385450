.ImageGallery {
  header {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .fc__facilities {
    padding: 1rem 0;
    header {
      font-weight: 600;
      color: var(--text-main);
      margin-bottom: 16px;
    }

    &-main {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 0;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        width: 25%;

        span:last-child {
          font-size: 0.875rem;
          color: var(--text-main);
        }
      }
    }
  }
}

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 90vw;
  position: relative;
  margin: auto;
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px 0 10px;
  border: 2px solid var(--color-primary);
}

/* Hide the images by default */
.mySlides {
  display: none;

  &:first-child {
    display: block;
  }
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #ffffff;
  font-size: 14px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: calc(100% - 20px);
  text-align: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  height: 54px;
  overflow: hidden;
}

/* Number text (1/3 etc) */
.numbertext {
  color: var(--color-primary);
  font-size: 14px;
  padding: 16px 12px;
  position: absolute;
  top: 0;

  span {
    font-size: 24px;
    font-weight: bold;
  }
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

// .active,
// .dot:hover {
//   background-color: #717171;
// }

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1s;
  animation-timing-function: ease;
}

@keyframes fade {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}
