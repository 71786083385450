@import "../../styles/responsive";

.Navbar {
  background-color: rgba(var(--color-primary-rgb), 1);
  .nav {
    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 4px 0;
      list-style: none;
      margin: 0;

      li {
        a {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          color: var(--text-white);
        }
      }
    }
  }
}
