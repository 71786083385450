@import "../../../../styles/responsive";

.ViewInMap {
  .MuiPaper-root {
    border: 2px solid var(--color-primary);
    height: 90vh;
    width: 98vw;
    margin: 0;
    max-height: calc(100vh - 32px);
    max-width: calc(100vw - 32px);
    border-radius: 10px;
    > div {
      padding: 0;
      overflow: hidden;
    }
  }
  .vim {
    &__map-container {
      height: 100%;
      position: relative;
    }

    &__img-container {
      height: 80px;
      width: 80px;
      border-radius: 6px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      flex-shrink: 0;
      @include tablet-lg {
        height: 180px;
        width: 100%;
        margin-bottom: 4px;
      }
    }

    &__hd-container {
      position: absolute;
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
      width: calc(100% - 2rem);
      height: 96px;
      overflow: auto;
      background-color: #fff;
      bottom: 1rem;
      left: 1rem;
      border-radius: 10px;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      padding: 8px;

      @include tablet-lg {
        height: calc(100% - 2rem);
        width: 320px;
        flex-direction: column;
        padding: 1rem;
      }

      .HostelName {
        width: calc(100% - 96px);

        @include tablet-lg {
          width: auto;
        }

        h3 {
          margin: 0;
          white-space: nowrap;
        }
      }

      .RoomOptions {
        display: none;

        @include tablet-lg {
          display: block;
        }
      }
    }

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: rgba(255, 255, 255, 1);
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      cursor: pointer;

      @include tablet-lg {
        top: 2rem;
        right: 2rem;
      }
    }
  }
}
