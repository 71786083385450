@import "../styles/responsive";

.Authentication {
  .auth {
    &__main {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @include tablet-lg {
        flex-direction: row;
        flex: 1;
      }

      section {
        @include tablet-lg {
          flex: 1 1 50%;
          height: 100vh;
        }
      }
    }

    &__right-section {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      overflow: auto;
    }

    &__welcome-msg {
      h4,
      h6 {
        font-weight: 400;
        margin-bottom: 10px;
      }

      @include tablet-lg {
        padding-top: 80px;
      }
    }

    &__form-container {
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      max-width: 320px;

      > a {
        font-size: 0.875rem;
        align-self: flex-end;
        color: var(--color-primary);
      }
    }

    &__btn {
      width: 240px;
    }

    &__social {
      padding-top: 20px;

      @include tablet-lg {
        width: 480px;
        margin: 0 auto;
      }
      &-or {
        margin: 0 40px 20px;
        color: var(--text-secondary);
        max-width: 72%;
        margin: 0 auto;
        div:first-child {
          border-bottom: 1px solid var(--text-secondary);
        }
        div:last-child {
          position: relative;
          top: -13px;
          background: #fff;
          width: 48px;
          margin: 0 auto;
          text-align: center;
        }
      }

      &-google {
        border: 1px solid #bdbdbd;
        border-radius: 30px;
        padding: 4px;
        color: var(--text-main);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        font-size: 0.875rem;
        width: 210px;
        margin: 0 auto;

        img {
          width: 24px;
        }
      }
    }

    &__footer {
      padding: 1rem;
      display: flex;
      justify-content: center;

      @include tablet-lg {
        justify-content: flex-start;
        text-align: left;
      }

      p {
        margin: 0;
        font-size: 0.875rem;
        color: var(--text-secondary);
        font-weight: 500;
      }

      a {
        font-size: 0.875rem;
        text-decoration: underline;
        color: var(--color-primary);
      }
    }
  }

  .component__textbox-big {
    width: 360px;
    max-width: 100%;
  }

  .auth__form .component__textbox-big input {
    color: var(--text-main);
  }
}
