@import "../../../../styles/responsive";

.HL-ViewInMap {
  .MuiPaper-root {
    border: 2px solid var(--color-primary);
    height: 90vh;
    width: 98vw;
    margin: 0;
    max-height: calc(100vh - 32px);
    max-width: calc(100vw - 32px);
    border-radius: 10px;
    > div {
      padding: 0;
      overflow: hidden;
    }

    h3 {
      margin: 0;
    }
  }
  .hl-vim {
    &__map-container {
      height: 100%;
      position: relative;
    }

    &__img-container {
      height: 80px;
      width: 80px;
      border-radius: 6px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @include tablet-lg {
        height: 140px;
        width: 100%;
        margin-bottom: 4px;
      }
    }

    &__hl-container {
      position: absolute;
      display: flex;
      gap: 1rem;
      flex-wrap: nowrap;
      width: calc(100% - 2rem);
      bottom: 1rem;
      left: 1rem;
      border-radius: 10px;
      overflow: auto;

      @include tablet-lg {
        width: 280px;
        height: calc(100% - 2rem);
        flex-direction: column;
        padding: 1rem;
        background-color: #fff;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      }
    }

    &__hl-tile {
      display: flex;
      gap: 8px;
      width: calc(100% - 20px);
      background-color: #fff;
      padding: 0.5rem;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      @include tablet-lg {
        width: 100%;
        flex-direction: column;
        border-bottom: 1px solid #bdbdbd;
        padding: 0 0 1rem;
        border-right: 0;
        gap: 0;
        box-shadow: none;
        border-radius: 0;
        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &__name {
      &-container {
        width: calc(100% - 88px);

        @include tablet-lg {
          width: auto;
        }
      }
      h3 {
        margin: 0;
        white-space: nowrap;
      }
    }

    &__location {
      p {
        margin: 0;
        color: var(--text-main);
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        gap: 4px;

        .mat-icon {
          font-size: 1.125rem;
        }

        > span:last-child {
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc(100% - 20px);
          white-space: nowrap;
        }
      }
    }

    &__rating {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mat-icon {
        font-size: 1.25rem;
      }

      > span {
        display: flex;
        gap: 2px;
        font-size: 0.875rem;

        &:last-child {
          cursor: pointer;
        }
        .mat-icon {
          color: #f6de00;
        }
      }
    }

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: rgba(255, 255, 255, 1);
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      cursor: pointer;

      @include tablet-lg {
        top: 2rem;
        right: 2rem;
      }
    }
  }
}
