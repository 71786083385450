@import "../../styles/responsive";

.ManageBookings {
  .mb {
    &__main-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0;

      @include tablet-lg {
        flex-direction: row;
      }
    }

    &__left {
      flex: 1;
    }

    &__right {
      flex: 0 0 200px;
      border-radius: 6px;
      background-color: rgba(var(--color-primary-rgb), 0.1);
    }

    &__tile {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      padding: 16px;
      border-radius: 6px;
      margin-bottom: 30px;

      &-header {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 20px;

        @include tablet-lg {
          flex-direction: row;
        }

        img {
          width: 120px;
          border-radius: 6px;
        }

        h3 {
          margin: 0;
          font-weight: normal;
          color: var(--color-primary);
        }

        p {
          margin-top: 0;
          margin-bottom: 4px;
          font-size: 14px;
        }
      }

      &-main {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;

        @include tablet-lg {
          flex-direction: row;
        }

        > div {
          display: flex;
          align-items: center;
          gap: 8px;
          padding-right: 14px;
          width: 100%;
          flex-grow: 0;
          flex-shrink: 0;

          @include tablet-lg {
            width: 30%;
            border-right: 1px solid #e5e5e5;
          }

          &:last-child {
            border: none;
          }
        }

        label {
          font-size: 14px;
        }

        p {
          margin: 0;
          font-size: 16px;
        }

        .mat-icon {
          font-size: 28px;
          color: #bdbdbd;

          &.Cancelled {
            color: var(--color-error);
          }
        }
      }
    }
  }
}
