@import "../../styles/responsive";

.ContactUs {
  .cu {
    &__main-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0;

      @include tablet-lg {
        flex-direction: row;
      }
    }

    &__left {
      flex: 1;
    }

    &__right {
      flex: 0 0 200px;
      border-radius: 6px;
      background-color: rgba(var(--color-primary-rgb), 0.1);
    }

    &__top-toolbar {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 0 0;
    }
  }
}
