@mixin mobile {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin tablet-lg {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin laptp-lg {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin desktop-lg {
  @media screen and (min-width: 2560px) {
    @content;
  }
}
