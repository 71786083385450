@import "../authentication.scss";

.Login {
  .auth {
    &__form {
      .component__textbox-big input {
        color: var(--text-main);
      }
    }
  }

  #googleLogin {
    width: 240px;
    margin: 0 auto;
  }
}
