@import "../../styles/responsive";

.BookingSuccess {
  .bs {
    &__main-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem 0;
      text-align: center;
      padding: 4rem 0;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 186px);

      h2 {
        color: var(--color-primary);
      }

      .mat-icon {
        font-size: 4rem;
        color: var(--color-success);
      }
    }

    &__cta-btn {
      border: 1px solid var(--color-primary);
      border-radius: 4px;
      color: var(--color-primary);
      padding: 16px 40px;
    }
  }
}
