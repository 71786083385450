.VerifyAccount {
  .auth {
    &__left-section {
      background-image: url("../../../public/images/room3.jpg");
    }

    &__form-container {
        p {
            color: var(--text-secondary);
            font-size: 0.875rem;
            font-weight: 500;
        }
    }

    &__form {
        flex-direction: row;
    }
  }
}
