:root {
  --color-primary-rgb: 255, 102, 0;
  --color-secondary-rgb: 50, 50, 50;
  --color-primary: #ff6600;
  --color-secondary: #323232;
  --text-main: #323232;
  --text-secondary: #211a17b8;
  --text-black-lighter: #535353;
  --text-white: #fff;
  --color-male: #1976d2;
  --color-female: #ff4081;
  --color-neutral: #616161;
  --color-success: #4caf50;
  --color-error: #c62828;
}

body #root {
  color: var(--color-secondary);
}

.text-main {
  color: var(--text-main);
}

.text-secondary {
  color: var(--text-secondary);
}

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  color: var(--color-secondary);
}

input[type="text"],
input[type="password"],
input[type="email"] {
  color: var(--text-secondary);
}

.MuiButtonBase-root.MuiButton-containedPrimary {
  color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

div.css-mrrbnq-MuiInputBase-root-MuiOutlinedInput-root,
th.css-1ch8lmo-MuiTableCell-root,
td.css-1ocoq2w-MuiTableCell-root {
  color: var(--color-secondary);
}

img {
  object-fit: cover;
}