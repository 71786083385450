@import "../../styles/responsive";

.Register {
  .auth {
    &__left-section {
      background: url("../../../public/images/room2.jpg");
    }

    &__step2 {
      // align-items: flex-start;
      color: var(--color-secondary);
      h4 {
        margin-bottom: 0;
        font-weight: normal;
      }
      &-otp {
        display: flex;
        gap: 8px;
      }

      .component__textbox-otp {
        width: 64px;
        height: 56px;
        input {
          font-size: 2rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          text-align: center;
        }
      }
    }

    &__step4 {
      .mat-icon {
        font-size: 3rem;
        color: var(--color-primary);
      }
    }
  }

  .register {
    &__pwd-help {
      align-self: flex-end;
      font-size: 14px;
      position: relative;
      top: -8px;
      cursor: pointer;
    }
  }

  .register1 {
    &__pwd-help {
      align-self: flex-end;
      font-size: 12px;
      position: relative;
      top: -8px;
      cursor: pointer;
    }
  }
}
