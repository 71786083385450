@import "../../../styles/responsive";

.RoomOptions {
  .ro {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      margin-top: 0.75rem;
      margin-bottom: 1rem;
      overflow: auto;
      padding-bottom: 8px;
      flex-direction: column;
      max-width: 100%;

      @include tablet-lg {
        width: 432px;
      }

      > div {
        flex: 0 0 160px;
        background-color: rgba(var(--color-primary-rgb), 0.1);
        padding: 12px;
        border-radius: 10px;
        color: var(--text-main);
        height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          font-weight: 500;
          margin: 0;
          text-transform: capitalize;
          font-size: 1rem;
          max-height: 48px;
          overflow: hidden;
        }

        p {
          font-size: 13px;
          margin: 0;
          height: 40px;
          overflow: hidden;
        }

        .price {
          color: var(--text-main);
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          flex-wrap: wrap;

          span:nth-child(2) {
            line-height: 1;
            font-size: 1rem;
            font-weight: 600;
          }

          span:last-of-type {
            font-size: 0.75rem;
            line-height: 1;
          }
        }

        &.occupied {
          background-color: #f5f5f5;
          position: relative;

          span,
          h3,
          .price,
          .mat-icon {
            color: rgba($color: #000000, $alpha: 0.48);
          }

          &:after {
            content: "Booked";
            position: absolute;
            right: 6px;
            top: 6px;
            background-color: #c1272d;
            color: #fff;
            border-radius: 20px;
            padding: 0 8px;
            font-size: 12px;
          }
        }
      }

      @include tablet-lg {
        display: flex;
      }
    }

    &__icon {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mat-icon {
        font-size: 2.5rem;
        color: #6227c1;
      }
    }

    &__header {
      font-weight: 600;
      color: var(--text-main);
    }

    &__add-info {
      display: flex;
      font-size: 14px;
      border-top: 1px solid rgba(var(--color-secondary-rgb), 0.1);
      padding-top: 8px;
      gap: 30px;
      text-align: left;

      > div {
        display: flex;
        justify-content: space-between;
        flex: 1;

        .mat-icon {
          font-size: 12px;
        }

        > span:last-child {
          text-align: right;
          font-weight: bold;
          width: 53px;
          flex-shrink: 0;
        }
      }
    }
  }
}
